<script>
export default {
	name: 'LoginRecover',
	data(){
		return {
			emailMasked: this.$store.state.mailRecover
		}
	},
	methods:{
		goto(){
			this.$router.push({name: 'login.home'});
		}
	}
}
</script>

<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Que bom ter você na {{ $store.state.ie.shortname }}!
			</h2>
			<h3 class="subtitle">
				Pronto. Enviamos sua senha para o e-mail abaixo:
			</h3>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-6-fullhd is-offset-3-fullhd">
				<div class="columns is-multiline">
					<div class="column is-12 has-text-centered">
						<div class="columns">
							<div class="column is-8-fullhd is-offset-2-fullhd">
								<div class="box-email-masked">
									{{ emailMasked }}
								</div>
							</div>
						</div>

						<h3 class="subtitle mt-5">
							Não sabe seu e-mail ou não possui mais acesso a ele?
						</h3>
					</div>

					<div class="column is-12 has-text-centered">
						<router-link
							to="/login/sign-up"
							class="is-link is-inverted has-text-primary p-4"
						>
							Altere seu e-mail de cadastro
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@styles/_variables';

.box-email-masked{
    font-weight: $font-weight-700;
    font-weight: var(--font-weight-700);
    background-color: $white;
    background-color: var(--white);
    color: $dark-blue;
    color: var(--dark-blue);
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 22px;
    display: flex;
    height: 60px;
    width: 100%;
}
</style>